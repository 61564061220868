import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";

import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Gmail from "../assets/images/gmail-icon.svg";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
import Facebook from "../assets/images/facebook-512.svg";
import Instagram from "../assets/images/Instagram.svg";
import RNG from "../assets/images/providers/RNGCertified.png";
import Gambling from "../assets/images/providers/GamblingCommission.png";
import ResponsibleG from "../assets/images/providers/ResponsibleGamingFounding.png";
import Gamble18 from "../assets/images/providers/18+gambleresponsibly.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import UPIImg from "../assets/images/providers/upi.png";
import VisaMasterCardImg from "../assets/images/providers/visa_master-card.png";
import PaytmImg from "../assets/images/providers/paytm.png";
import NetBankingImg from "../assets/images/providers/net-banking.png";

import BitcoinImg from "../assets/images/providers/crypto/Bitcoin.png";
import EthereumImg from "../assets/images/providers/crypto/Ethereum.png";
import TetherImg from "../assets/images/providers/crypto/Tether.png";
import DaiCoinImg from "../assets/images/providers/crypto/DaiCoin.png";
import DogecoinImg from "../assets/images/providers/crypto/Dogecoin.png";
import LitecoinImg from "../assets/images/providers/crypto/Litecoin.png";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [activePage, setActivePage] = useState("");
  const location = useLocation();

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "deposit",
    "exchange",
    "betby",
    "/sports/Inplay",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} sm={4} md={3} className="text-center">
            <div className="foot-logo">
              <a href="/">
                <img src={appDetails?.LOGO_URL} alt="Logo" />
              </a>
            </div>
            <div className="about-site">
              <p>{appDetails?.FOOTER_TEXT || ""}</p>
            </div>
            <div className="social-icons">
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "javascript:void(0)"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.TELEGRAM != ""
                        ? appDetails?.TELEGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.FACEBOOK != ""
                        ? appDetails?.FACEBOOK
                        : "javascript:void(0)"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                  </a>
                </li>
              </ul>
            </div>
          </Col>

          <Col xs={12} sm={4} md={3}>
            <div className="quick-links">
              <h5>Quick Links</h5>
              <ul>
                <li>
                  <a href="/contactus">Contact Us</a>
                </li>
                <li>
                  <a href="/privacypolicy ">Privacy Policy</a>
                </li>
                <li>
                  <a href="/responsiblegame">Responsible Gaming</a>
                </li>
                <li>
                  <a href="/fairplay">Fair Play</a>
                </li>
                <li>
                  <a href="/gamesrule">Games Rules</a>
                </li>
                <li>
                  <a href="/terms">Terms and Conditions</a>
                </li>
              </ul>
            </div>
          </Col>

          <Col xs={12} sm={4} md={3}>
            <div className="contactus">
              <h5>Contact Us</h5>
              <ul>
                <li>
                  <a className="panel" href="mailto:support@betrich.io">
                    <img src={Gmail} alt="email" />
                    <span>support@betrich.io</span>
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "javascript:void(0)"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                    <span>{appDetails?.WHATS_APP_URL}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.TELEGRAM != ""
                        ? appDetails?.TELEGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                    <span>{appDetails?.TELEGRAM}</span>
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.FACEBOOK != ""
                        ? appDetails?.FACEBOOK
                        : "javascript:void(0)"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                    <span>{appDetails?.FACEBOOK}</span>
                  </a>
                </li>
              </ul>
            </div>

            {/* <div className="social-icons d-sm-none">
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "javascript:void(0)"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.TELEGRAM != ""
                        ? appDetails?.TELEGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.FACEBOOK != ""
                        ? appDetails?.FACEBOOK
                        : "javascript:void(0)"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                  </a>
                </li>
              </ul>
            </div> */}
          </Col>

          <Col xs={12} md={3} className="d-flex d-md-block">
            <div className="responsible-gaming">
              <h5>Responsible Gaming</h5>
              <ul>
                <li>
                  <img
                    src={RNG}
                    alt="RNG Certified"
                    style={{ maxWidth: "90px" }}
                  />
                </li>
                <li>
                  <img
                    src={Gambling}
                    alt="Gambling Commission"
                    style={{ maxWidth: "80px" }}
                  />
                </li>
                <li>
                  <img
                    src={ResponsibleG}
                    alt="Responsible Gaming"
                    style={{ maxWidth: "120px" }}
                  />
                </li>
                <li>
                  <img
                    src={Gamble18}
                    loading="lazy"
                    alt="Gamble Responsible"
                    style={{ maxWidth: "120px" }}
                  />
                </li>
              </ul>
            </div>

            <div className="payment-methods">
              <h5>Payment Methods</h5>
              <ul>
                <li>
                  <img src={UPIImg} alt="upi" />
                </li>
                <li>
                  <img
                    src={VisaMasterCardImg}
                    alt="visa/master casrd"
                    style={{ maxWidth: "35px" }}
                  />
                </li>
                <li>
                  <img src={PaytmImg} alt="paytm" />
                </li>
                <li>
                  <img src={NetBankingImg} alt="net banking" />
                </li>
              </ul>
              <ul className="crypto-icons">
                <li>
                  <img src={BitcoinImg} alt="bitcoin" />
                </li>
                <li>
                  <img src={EthereumImg} alt="ethereum" />
                </li>
                <li>
                  <img src={TetherImg} alt="tether" />
                </li>
                <li>
                  <img src={DaiCoinImg} alt="daiCoin" />
                </li>
                <li>
                  <img src={DogecoinImg} alt="dogecoin" />
                </li>
                <li>
                  <img src={LitecoinImg} alt="litecoin" />
                </li>
              </ul>
              {/* <p style={{ color: "#848484" }}>
                  Accept, process &amp; disburse digital payments for your
                  business.
                </p> */}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12" className="text-center">
            <p className="mt-4 mb-0">
              &copy; 2024 betrich.io. All rights reserved.
            </p>
          </Col>
        </Row>

        <div className="helpline d-none">
          <a
            className="panel"
            href={
              appDetails?.WHATS_APP_URL != ""
                ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                : "javascript:void(0)"
            }
            target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
          >
            <img src={Whatsapp} /> Whatsapp <p>{appDetails?.WHATS_APP_URL}</p>
          </a>
          <div className="facebook-footer">
            <a
              className="panel"
              href={
                appDetails?.TELEGRAM != ""
                  ? appDetails?.TELEGRAM
                  : "javascript:void(0)"
              }
              target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
            >
              <img src={Telegram} alt="telegram" />
            </a>
            <a
              className="panel social-icon watsapp"
              href={
                appDetails?.FACEBOOK != ""
                  ? appDetails?.FACEBOOK
                  : "javascript:void(0)"
              }
              target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
            >
              <img src={Facebook} />
            </a>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-2 d-none">
          <p style={{ color: "rgb(204, 204, 204)" }}>
            Accept, process &amp; disburse digital payments for your business.
          </p>
        </div>

        <div className="d-flex justify-content-center mt-3 mb-3 d-none">
          <img
            src={Betfair}
            loading="lazy"
            className="mb-3 betfair"
            alt="Betfair Logo"
          />
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                className={activePage === "/sports/Inplay" ? "active" : ""}
                href="/sports/Inplay"
              >
                <SportsIcon />
                <span>Exchange</span>
              </a>
            </li>
            {/* <li>
              <a href="/sports">
                <img src={Exchange} alt="Exchange Icon" />
                <span>Exchange</span>
              </a>
            </li> */}
            <li>
              <a
                className={activePage === "/betby" ? "active" : ""}
                href="/betby"
              >
                <SportsIcon />
                <span>Sportsbook</span>
              </a>
            </li>
            <li>
              <a className={activePage === "home" ? "active" : ""} href="/home">
                <HomeIcon />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi">
                <GamesIcon />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "deposit" ? "active" : ""}
                href="/gatewaylist"
              >
                <CashierIcon />
                <span>Deposit</span>
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
