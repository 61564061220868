import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/images/telegram-icon.svg";
import WhatsappIcon from "../assets/images/whatsapp-icon.svg";
import FBIcon from "../assets/images/facebook-icon.svg";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/icons/btmhead_inplay.png";
import BotHeadIcon2 from "../assets/images/icons/btmhead_cricket.png";
import BotHeadIcon3 from "../assets/images/icons/btmhead_soccer.png";
import BotHeadIcon4 from "../assets/images/icons/btmhead_aviator.png";
import BotHeadIcon5 from "../assets/images/icons/btmhead_drgntgr.png";
import BotHeadIcon6 from "../assets/images/icons/btmhead_teenpati.png";
import BotHeadIcon7 from "../assets/images/icons/btmhead_roulette.png";

import SportsIcon1 from "../assets/images/games-icon/exchange_icon.png";
import HomeIcon1 from "../assets/images/games-icon/home_icon.png";
import EzugiIcon from "../assets/images/games-icon/ezugi_icon.png";
import EvolutionIcon from "../assets/images/games-icon/evolution_icon.png";
import CashierIcon1 from "../assets/images/games-icon/cashier_icon.png";
import Exchange from "../assets/images/games-icon/sports_icon.png";

import AllGames from "../assets/images/games-icon/allgames-icon.png";
import CricketIcon from "../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "../assets/images/games-icon/tennis-icon.png";
import GreyhoundRacingIcon from "../assets/images/games-icon/greyhoundracing-icon.png";
import HorseRacingIcon from "../assets/images/games-icon/horseracing-icon.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";
import VirtualCricketIcon from "../assets/images/games-icon/virtualcricket-icon.png";
import SnookerIcon from "../assets/images/games-icon/snooker-icon.png";
import SpaceIcon from "../assets/images/games-icon/space-icon.png";

import ArcadeGames from "../assets/images/games-icon/arcade-games.png";
import Aviator from "../assets/images/games-icon/aviator.svg";
import Dice from "../assets/images/games-icon/dice.svg";
import Plinko from "../assets/images/games-icon/plinko.svg";
import Goal from "../assets/images/games-icon/goal.svg";
import Hilo from "../assets/images/games-icon/hilo.svg";
import Mines from "../assets/images/games-icon/mines.svg";
import Keno from "../assets/images/games-icon/keno.svg";
import Miniroulette from "../assets/images/games-icon/miniroulette.svg";
import Hotline from "../assets/images/games-icon/hotline.svg";

import { useSelector } from "react-redux";
import ModalWrapper from "./ModalWrapper";
import { APP_CONST } from "../config/const";
import { AiOutlineClose } from "react-icons/ai";
import { getNotifications } from "../redux/app/actions";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    console.log("modal Working");

    setDefaultModal(modalName);
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    console.log(showAuthModals, "showAuthModals");
  }, [showAuthModals]);

  // const [isSubMenuActive, setIsSubMenuActive] = useState(false);
  const [isArcadeGamesActive, setIsArcadeGamesActive] = useState(false);
  const [isAllSportsActive, setIsAllSportsActive] = useState(false);
  // const inlineStyle = isSubMenuActive ? { overflowX: "clip" } : {};
  const inlineStyle =
    isArcadeGamesActive || isAllSportsActive ? { overflowX: "clip" } : {};

  // const handleMenuClick = () => {
  //   setIsSubMenuActive(!isSubMenuActive);
  // };
  const handleArcadeGamesClick = () => {
    setIsArcadeGamesActive(!isArcadeGamesActive);
    setIsAllSportsActive(false); // Close AllSports dropdown if it's open
  };
  const handleAllSportsClick = () => {
    setIsAllSportsActive(!isAllSportsActive);
    setIsArcadeGamesActive(false); // Close Arcade Games dropdown if it's open
  };

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee
            onMouseOver={(e) => e.target.stop()}
            onMouseOut={(e) => e.target.start()}
          >
            {websiteNotifications.map((notf, idx) => notf?.content + " ")}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}

      <header className="header beforeheader">
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>

                  <div className="middle-part">
                    <Navbar expand="lg">
                      <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto">
                            <Nav.Link href="/">
                              {/* <HomeIcon />  */}
                              <img src={HomeIcon1} />
                              Home
                            </Nav.Link>
                            <Nav.Link
                              onClick={() => {
                                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                              }}
                              className="exchange-nav"
                            >
                              {/* <SportsIcon /> */}
                              <div>
                                {/* <SportsIcon /> */}
                                <img src={SportsIcon1} />
                              </div>
                              Exchange
                            </Nav.Link>
                            <Nav.Link
                              onClick={() => {
                                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                              }}
                            >
                              <img src={Exchange} alt="Exchange Icon" />{" "}
                              Sportsbook
                            </Nav.Link>
                            <Nav.Link
                              onClick={() => {
                                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                              }}
                            >
                              <img src={EzugiIcon} /> Ezugi
                            </Nav.Link>
                            <Nav.Link
                              onClick={() => {
                                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                              }}
                            >
                              {/* <GamesIcon />  */}
                              <img src={EvolutionIcon} />
                              Evolution
                            </Nav.Link>
                            <Nav.Link
                              onClick={() => {
                                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                              }}
                            >
                              {/* <CashierIcon /> */}
                              <img src={CashierIcon1} />
                              Cashier
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </Navbar>
                  </div>

                  <div className="headerRight">
                    {/* <ul className="social">
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <img src={WhatsappIcon} alt="whats app" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <img src={TelegramIcon} alt="telegram" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.FACEBOOK != ""
                              ? appDetails?.FACEBOOK
                              : "javascript:void(0)"
                          }
                          target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                        >
                          <img src={FBIcon} />
                        </a>
                      </li>
                    </ul> */}

                    <Button
                      variant="primary"
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                      className="button-primary btn_signin"
                    >
                      SIGN IN
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        // handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                        window.location.href = "/login/otpless";
                      }}
                      className="button-primary btn_signup ms-2"
                    >
                      SIGN UP
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bottom_head">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <ul style={inlineStyle}>
                  {/* <li>
                    <a
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <img src={BotHeadIcon1} alt="bottom head link icon" />
                      <span>Inplay</span>
                    </a>
                  </li> */}
                  <li className="allGames">
                    <a
                      href="javascript:void(0)"
                      onClick={handleArcadeGamesClick}
                    >
                      <img src={ArcadeGames} alt="all games" />
                      <span>Arcade Games</span>
                    </a>
                    <ul
                      className={`sports-games spribe ${
                        isArcadeGamesActive ? "active" : ""
                      }`}
                    >
                      <li>
                        <a href="/casino/spribe/aviator">
                          <img src={Aviator} alt="bottom head link icon" />
                          <span>Aviator</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/dice">
                          <img src={Dice} alt="bottom head link icon" />
                          <span>Dice</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/plinko">
                          <img src={Plinko} alt="bottom head link icon" />
                          <span>Plinko</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/goal">
                          <img src={Goal} alt="bottom head link icon" />
                          <span>Goal</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/hilo">
                          <img src={Hilo} alt="bottom head link icon" />
                          <span>Hilo</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/mines">
                          <img src={Mines} alt="bottom head link icon" />
                          <span>Mines</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/keno">
                          <img src={Keno} alt="bottom head link icon" />
                          <span>Keno</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/miniroulette">
                          <img src={Miniroulette} alt="bottom head link icon" />
                          <span>Mini Roulette</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/hotline">
                          <img src={Hotline} alt="bottom head link icon" />
                          <span>Hotline</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="allGames">
                    <a href="javascript:void(0)" onClick={handleAllSportsClick}>
                      <img src={AllGames} alt="all games" />
                      <span>AllSports</span>
                    </a>
                    <ul
                      className={`sports-games ${
                        isAllSportsActive ? "active" : ""
                      }`}
                    >
                      <li>
                        <a href="/sports/Cricket">
                          <img src={VirtualCricketIcon} alt="e-sports" />
                          <span>E-Sports</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Cricket">
                          <img src={CricketIcon} alt="bottom head link icon" />
                          <span>E-Sports</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Cricket">
                          <img src={CricketIcon} alt="bottom head link icon" />
                          <span>Cricket</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Soccer">
                          <img src={SoccerIcon} alt="soccer" />
                          <span>Football</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Tennis">
                          <img src={TennisIcon} alt="bottom head link icon" />
                          <span>Tennis</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Horse Racing">
                          <img src={HorseRacingIcon} alt="horse racing" />
                          <span>Horse Racing</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Greyhound Racing">
                          <img
                            src={GreyhoundRacingIcon}
                            alt="greyhound racing"
                          />
                          <span>Greyhound Racing</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Politics">
                          <img src={PoliticsIcon} alt="politics" />
                          <span>Politics</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Snooker">
                          <img src={SnookerIcon} alt="snooker" />
                          <span>Snooker</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/SPACE">
                          <img src={SpaceIcon} alt="space" />
                          <span>SPACE</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <img src={BotHeadIcon4} alt="bottom head link icon" />
                      <span>Aviator</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <img src={BotHeadIcon5} alt="bottom head link icon" />
                      <span>DragonTiger</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <img src={BotHeadIcon6} alt="bottom head link icon" />
                      <span>TeenPatti</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <img src={BotHeadIcon7} alt="bottom head link icon" />
                      <span>Roulette</span>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
        {showAuthModals && (
          <ModalWrapper
            defaultShow={defaultModal}
            handleClose={setShowAuthModals}
          />
        )}
      </header>
    </>
  );
};

export default Header;
