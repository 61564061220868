import React, { useEffect, useState } from "react";
import {
  Button,
  Offcanvas,
  Modal,
  Nav,
  Navbar,
  Container,
  Row,
  Col,
  Dropdown,
  Overlay,
} from "react-bootstrap";
import MenuIcon from "../assets/svg/MenuIcon";
import ProfileIcon from "../assets/svg/ProfileIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/images/whatsapp-icon.svg";
import TelegramIcon from "../assets/images/telegram-icon.svg";
import FBIcon from "../assets/images/facebook-icon.svg";
import refferal from "../assets/images/refferal-icon.png";

import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import SupportIcon from "../assets/images/support-icon.png";
import LogoutIcon from "../assets/svg/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";

import SportsIcon from "../assets/svg/SportsIcon";
import numberWithCommas from "../utils/numbersWithComma";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import { IoWalletOutline } from "react-icons/io5";
import ExchangeFundsFormPopUp from "../views/components/ExchangeFundsFormPopUp";
import { CasinoProviders } from "../lib/data";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";

import SportsIcon1 from "../assets/images/games-icon/exchange_icon.png";
import HomeIcon1 from "../assets/images/games-icon/home_icon.png";
import EzugiIcon from "../assets/images/games-icon/ezugi_icon.png";
import EvolutionIcon from "../assets/images/games-icon/evolution_icon.png";
import CashierIcon1 from "../assets/images/games-icon/cashier_icon.png";
import Exchange from "../assets/images/games-icon/sports_icon.png";
import BotHeadIcon1 from "../assets/images/icons/btmhead_inplay.png";
import BotHeadIcon2 from "../assets/images/icons/btmhead_cricket.png";
import BotHeadIcon3 from "../assets/images/icons/btmhead_soccer.png";
import BotHeadIcon4 from "../assets/images/icons/btmhead_aviator.png";
import BotHeadIcon5 from "../assets/images/icons/btmhead_drgntgr.png";
import BotHeadIcon6 from "../assets/images/icons/btmhead_teenpati.png";
import BotHeadIcon7 from "../assets/images/icons/btmhead_roulette.png";

import AllGames from "../assets/images/games-icon/allgames-icon.png";
import CricketIcon from "../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "../assets/images/games-icon/tennis-icon.png";
import GreyhoundRacingIcon from "../assets/images/games-icon/greyhoundracing-icon.png";
import HorseRacingIcon from "../assets/images/games-icon/horseracing-icon.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";
import VirtualCricketIcon from "../assets/images/games-icon/virtualcricket-icon.png";
import SnookerIcon from "../assets/images/games-icon/snooker-icon.png";
import SpaceIcon from "../assets/images/games-icon/space-icon.png";

import ArcadeGames from "../assets/images/games-icon/arcade-games.png";
import Aviator from "../assets/images/games-icon/aviator.svg";
import Dice from "../assets/images/games-icon/dice.svg";
import Plinko from "../assets/images/games-icon/plinko.svg";
import Goal from "../assets/images/games-icon/goal.svg";
import Hilo from "../assets/images/games-icon/hilo.svg";
import Mines from "../assets/images/games-icon/mines.svg";
import Keno from "../assets/images/games-icon/keno.svg";
import Miniroulette from "../assets/images/games-icon/miniroulette.svg";
import Hotline from "../assets/images/games-icon/hotline.svg";

import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  // const [isSubMenuActive, setIsSubMenuActive] = useState(false);
  const [isArcadeGamesActive, setIsArcadeGamesActive] = useState(false);
  const [isAllSportsActive, setIsAllSportsActive] = useState(false);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  // const inlineStyle = isSubMenuActive ? { overflowX: "clip" } : {};
  const inlineStyle =
    isArcadeGamesActive || isAllSportsActive ? { overflowX: "clip" } : {};
  const [showToggle, setShowToggle] = useState(true);

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);

  useEffect(() => {
    setShowToggle(window.screen.width > 992);
    loadNotifications();
    return () => {};
  }, []);

  const handleRowClick = (item) => {
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  // const handleMenuClick = () => {
  //   setIsSubMenuActive(!isSubMenuActive);
  // };
  const handleArcadeGamesClick = () => {
    setIsArcadeGamesActive(!isArcadeGamesActive);
    setIsAllSportsActive(false); // Close AllSports dropdown if it's open
  };
  const handleAllSportsClick = () => {
    setIsAllSportsActive(!isAllSportsActive);
    setIsArcadeGamesActive(false); // Close Arcade Games dropdown if it's open
  };

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee
            onMouseOver={(e) => e.target.stop()}
            onMouseOut={(e) => e.target.start()}
          >
            {websiteNotifications.map((notf, idx) => notf?.content + " ")}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}

      <header className="header aftrlgn">
        <div className="top_head">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {/* <div className="leftbar_toggle d-xl-none">
                    <Button
                      variant="primary"
                      onClick={() => setShowLeftbarToggle((s) => !s)}
                    >
                      <MenuIcon />
                    </Button>
                  </div> */}
                    <div className="logo">
                      <a href="/home">
                        <img src={appDetails?.LOGO_URL} alt="Logo" />
                      </a>
                    </div>
                  </div>

                  <Navbar expand="xl" className="d-none d-xl-block">
                    <Navbar.Toggle
                      aria-controls="basic-navbar-nav"
                      onClick={() => setShowToggle((s) => !s)}
                    />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav>
                        <Nav.Link href="/home" className="logoImg d-xl-none">
                          <img src={appDetails?.LOGO_URL} alt="Logo" />
                        </Nav.Link>
                        <Nav.Link href="/home">
                          {/* <HomeIcon />  */}
                          <img src={HomeIcon1} />
                          Home
                        </Nav.Link>
                        <Nav.Link
                          href="/sports/Inplay"
                          className="exchange-nav"
                        >
                          {/* <SportsIcon /> */}
                          <div>
                            {/* <SportsIcon /> */}
                            <img src={SportsIcon1} />
                          </div>
                          Exchange
                        </Nav.Link>
                        <Nav.Link href="/betby">
                          <img src={Exchange} alt="Exchange Icon" /> Sportsbook
                        </Nav.Link>
                        <Nav.Link href={CasinoProviders["ezugi"]?.href}>
                          {/* <GamesIcon />  */}
                          <img src={EzugiIcon} />
                          Ezugi
                        </Nav.Link>
                        <Nav.Link href={CasinoProviders["evolution"]?.href}>
                          {/* <GamesIcon />  */}
                          <img src={EvolutionIcon} />
                          Evolution
                        </Nav.Link>
                        {/* <Nav.Link href={CasinoProviders["worldcasino"]?.href}>
                        <GamesIcon />
                        World Casino
                      </Nav.Link> */}
                        {/* <Nav.Link href="/games-all">
                          <GamesIcon /> Casino
                        </Nav.Link> */}
                        <Nav.Link href="/cashier">
                          {/* <CashierIcon />  */}
                          <img src={CashierIcon1} />
                          Cashier
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>

                  <div className="headerRight">
                    {/* <ul className="social">
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <img src={WhatsappIcon} alt="whats app" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <img src={TelegramIcon} alt="telegram" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel social-icon watsapp"
                          href={
                            appDetails?.FACEBOOK != ""
                              ? appDetails?.FACEBOOK
                              : "javascript:void(0)"
                          }
                          target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                        >
                          <img src={FBIcon} alt="facebook" />
                        </a>
                      </li>
                    </ul> */}

                    <Dropdown align="end" className="me-2">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="tel:{user?.mobileno}">
                          <b>{user?.mobileno}</b>
                        </Dropdown.Item>
                        <Dropdown.Item href="javascript:void(0)">
                          <h6>Balance</h6>
                          <span>{numberWithCommas(wallet?.cash || 0)}</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="javascript:void(0)"
                          onClick={() => setShowBetModal(true)}
                        >
                          <h6>Exposure</h6>
                          <span>{numberWithCommas(exposureAmount || 0)}</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="javascript:void(0)">
                          <h6>Bonus</h6>
                          <span>{numberWithCommas(wallet?.coins)}</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="/gatewaylist" className="deposit">
                          Deposit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <div className="funds me-2">
                      <ul>
                        {/* <li>
                        <div onClick={() => setShowBetModal(true)}>
                          <div>
                            Exp:{" "}
                            <span>{numberWithCommas(exposureAmount || 0)}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                        </a>
                      </li> */}
                        <li className="">
                          <a href="/gatewaylist" className="deposit">
                            Deposit
                          </a>
                        </li>
                      </ul>
                    </div>
                    <Button variant="primary menu-btn" onClick={handleShow}>
                      <ProfileIcon />
                      {/* <MenuIcon /> */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_head">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <ul style={inlineStyle}>
                  {/* <li>
                    <a href="/sports/Inplay">
                      <img src={BotHeadIcon1} alt="inplay" />
                      <span>Inplay</span>
                    </a>
                  </li> */}
                  <li className="allGames">
                    <a
                      href="javascript:void(0)"
                      onClick={handleArcadeGamesClick}
                    >
                      <img src={ArcadeGames} alt="all games" />
                      <span>Arcade Games</span>
                    </a>
                    <ul
                      className={`sports-games spribe ${
                        isArcadeGamesActive ? "active" : ""
                      }`}
                    >
                      <li>
                        <a href="/casino/spribe/aviator">
                          <img src={Aviator} alt="bottom head link icon" />
                          <span>Aviator</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/dice">
                          <img src={Dice} alt="bottom head link icon" />
                          <span>Dice</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/plinko">
                          <img src={Plinko} alt="bottom head link icon" />
                          <span>Plinko</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/goal">
                          <img src={Goal} alt="bottom head link icon" />
                          <span>Goal</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/hilo">
                          <img src={Hilo} alt="bottom head link icon" />
                          <span>Hilo</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/mines">
                          <img src={Mines} alt="bottom head link icon" />
                          <span>Mines</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/keno">
                          <img src={Keno} alt="bottom head link icon" />
                          <span>Keno</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/miniroulette">
                          <img src={Miniroulette} alt="bottom head link icon" />
                          <span>Mini Roulette</span>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/hotline">
                          <img src={Hotline} alt="bottom head link icon" />
                          <span>Hotline</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="allGames">
                    <a href="javascript:void(0)" onClick={handleAllSportsClick}>
                      <img src={AllGames} alt="all games" />
                      <span>AllSports</span>
                    </a>
                    <ul
                      className={`sports-games ${
                        isAllSportsActive ? "active" : ""
                      }`}
                    >
                      <li>
                        {/* <a href="/sports/Virtual Cricket"> */}
                        <a href="https://sportinfo.powerplay247.com/event/virtual-sports">
                          <img src={VirtualCricketIcon} alt="e-sports" />
                          <span>E-Sports</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Cricket">
                          <img src={CricketIcon} alt="bottom head link icon" />
                          <span>Cricket</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Soccer">
                          <img src={SoccerIcon} alt="soccer" />
                          <span>Football</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Tennis">
                          <img src={TennisIcon} alt="bottom head link icon" />
                          <span>Tennis</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Horse Racing">
                          <img src={HorseRacingIcon} alt="horse racing" />
                          <span>Horse Racing</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Greyhound Racing">
                          <img
                            src={GreyhoundRacingIcon}
                            alt="greyhound racing"
                          />
                          <span>Greyhound Racing</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Politics">
                          <img src={PoliticsIcon} alt="politics" />
                          <span>Politics</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Snooker">
                          <img src={SnookerIcon} alt="snooker" />
                          <span>Snooker</span>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/SPACE">
                          <img src={SpaceIcon} alt="space" />
                          <span>SPACE</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/casino/spribe/aviator">
                      <img src={BotHeadIcon4} alt="bottom head link icon" />
                      <span>Aviator</span>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/DragonTiger">
                      <img src={BotHeadIcon5} alt="bottom head link icon" />
                      <span>DragonTiger</span>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/TeenPatti">
                      <img src={BotHeadIcon6} alt="bottom head link icon" />
                      <span>TeenPatti</span>
                    </a>
                  </li>
                  <li>
                    <a href="/casino/ezugi/AutoRoulette">
                      <img src={BotHeadIcon7} alt="bottom head link icon" />
                      <span>Roulette</span>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        <Offcanvas show={show} onHide={handleClose} placement={"end"}>
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <ProfileIcon />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
                  <a>{user?.mobileno}</a>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row">
              <div className="col-6">
                <div className="balance mb-1">Main Balance</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-1">
                  {numberWithCommas(wallet?.cash || 0)}
                </div>
              </div>
              <div className="col-6">
                <div className="balance mb-3">Bonus</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-3">
                  {numberWithCommas(wallet?.coins) || 0}
                </div>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/withdraw");
                }}
              >
                <Button variant="primary  w-100">Withdraw</Button>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/gatewaylist");
                }}
              >
                <Button variant="primary w-100">Deposit</Button>
              </div>
              {/* <div className="col-4">
              <Button variant="primary w-100" onClick={handleShowModal}>
                Exchange
              </Button>
              <ExchangeFundsFormPopUp
                showexchangeModal={showexchangeModal}
                handleCloseModal={handleCloseModal}
              />
            </div> */}
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <ProfileIcon />
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <BetIcon />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <StatementIcon />
                  <span>Account Statement</span>
                </a>
              </li>
              <li>
                <a href="/referral">
                  <img src={refferal} />

                  <span className="bonus-space">Referral</span>
                </a>
              </li>
              {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
              {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
              <li>
                <a href="/contactus">
                  <img src={SupportIcon} alt="support" />
                  <span>Support Team</span>
                </a>
              </li>
              <li onClick={() => dispatch(logoutUser())}>
                <a href="#">
                  <LogoutIcon />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  {/* <span className="notification-count">2</span> */}
                  <img src={WhatsappIcon} alt="whats app" />
                </a>
              </li>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.TELEGRAM != ""
                      ? appDetails?.TELEGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={TelegramIcon} alt="telegram" />
                </a>
              </li>
              <li>
                <a
                  className="panel social-icon watsapp"
                  href={
                    appDetails?.FACEBOOK != ""
                      ? appDetails?.FACEBOOK
                      : "javascript:void(0)"
                  }
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src={FBIcon} alt="facebook" />
                </a>
              </li>
              <li className="theme-btn">
                <buton
                  type="button"
                  onClick={() => setShowLightModeToggle((s) => !s)}
                >
                  <div className="theme-icon">
                    <img
                      src={MoonLight}
                      alt="Moon Icon"
                      className="dark_theme"
                    />
                    <img
                      src={SunLight}
                      alt="Sun Icon"
                      className="light_theme"
                    />
                  </div>
                </buton>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
